import * as types from "./types";
import { toast } from "react-toastify";

export const updatePaymentMethods = (limit = 500, page = 1) => {
  return (dispatch) => {
    window.api
      .get(`/admin/payments/methods?limit=${limit}&page=${page}`)
      .then((data) => {
        dispatch({
          type: types.PAYMENT_METHODS_UPDATE,
          payload: data,
        });
      });
  };
};

export const updatePaymentMethod = (id) => {
  return (dispatch) => {
    window.api.get("/admin/payments/methods/" + id).then((data) => {
      dispatch({
        type: types.PAYMENT_METHOD_UPDATE,
        payload: data,
      });
    });
  };
};

export const updatePaymentProviders = (queryFilters = {}) => {
  queryFilters.page = queryFilters.page || 1;
  const queryFiltersToQueryString = (filters) => {
    const keys = Object.keys(filters || {})
      .filter(
        k => ![undefined, null, ''].includes(filters[k])
      );
    return keys.reduce((a, b, index) => {
      const qs = (filters[b] !== null && filters[b] !== undefined) ? `${index === 0 ? '' : '&'}${b}=${filters[b]}` : "";
      return a + qs
    }, "");
  }

  const queryString = queryFiltersToQueryString(queryFilters);
  return (dispatch) => {
    window.api.get("/admin/payments/providers?" + queryString).then((data) => {
      dispatch({
        type: types.PAYMENT_PROVIDERS_UPDATE,
        payload: data,
      });
    });
  };
};

export const updatePaymentProvider = (id) => {
  return (dispatch) => {
    window.api.get("/admin/payments/providers/" + id).then((data) => {
      dispatch({
        type: types.PAYMENT_PROVIDER_UPDATE,
        payload: data,
      });
    });
  };
};

export const updateCountryOrderings = () => {
  return (dispatch) => {
    window.api.get("/admin/payments/country_orderings").then((data) => {
      dispatch({
        type: types.PAYMENT_COUNTRY_ORDERINGS_UPDATE,
        payload: data,
      });
    });
  };
};

export const updatePaymentAccounts = (props) => {
  return (dispatch) => {
    window.api
      .get("/admin/payments/accounts", {
        params: props,
      })
      .then((data) => {
        dispatch({
          type: types.PAYMENT_ACCOUNTS_UPDATE,
          payload: data,
        });
      });
  };
};

export const createPaymentAccount = (type, user_id, props) => {
  return (dispatch) => {
    window.api
      .post(`/admin/payments/accounts/${type}/users/${user_id}`, props)
      .then((data) => {
        dispatch(updatePaymentAccounts());
      });
  };
};

export const createCountryOrdering = (props) => {
  return (dispatch) => {
    window.api.post("/admin/payments/country_orderings", props).then((data) => {
      dispatch(updateCountryOrderings());
    });
  };
};

export const updateCountryOrdering = (id) => {
  return (dispatch) => {
    window.api.get("/admin/payments/country_orderings/" + id).then((data) => {
      dispatch({
        type: types.PAYMENT_COUNTRY_ORDERING_UPDATE,
        payload: data,
      });
    });
  };
};
export const editCountryOrdering = (id, props) => {
  return (dispatch) => {
    window.api
      .put("/admin/payments/country_orderings/" + id, props)
      .then((data) => {
        dispatch(updateCountryOrdering(id));
      });
  };
};

export const editPaymentMethodPlatform = (id, platform) => {
  return (dispatch) => {
    return window.api
      .put(`/admin/payments/methods/${id}/platform`, { platform })
      .then(() => {
        dispatch(updatePaymentMethods);
      });
  };
};

export const editMethod = (id, props) => {
  return (dispatch) => {
    window.api.put("/admin/payments/methods/" + id, props).then((data) => {
      dispatch(updatePaymentMethods());
    });
  };
};

export const editProvider = (id, props) => {
  return (dispatch) => {
    return window.api
      .put("/admin/payments/providers/" + id, props)
      .then((data) => {
        dispatch(updatePaymentProvider(id));
      });
  };
};

export const deleteCountryOrdering = (id) => {
  return (dispatch) => {
    window.api
      .delete("/admin/payments/country_orderings/" + id)
      .then((data) => {
        dispatch(updateCountryOrderings());
      });
  };
};
