import React, { Component } from "react";
import Pagination from "../../shared/pagination";
import { Link } from "react-router-dom";
import moment from "moment";
import "./style.css";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  permissionsConstants,
  PermissionsFragment,
} from "../../app/permissions-fragment";
import { capitalizeFirstLetter } from "../../shared/utils/strings";
import { format } from "../../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { WrapperCalendar } from "../../shared/Calendar/WrapperCalendar";
import { currentUserHasRole } from "shared/roles";

const suggestFirstSelectedTab = () => {
  if (window.roles.includes('payment_agent')) return 1;
  if (window.roles.includes('payment_agent_tier_1')) return 1;
  if (window.roles.includes('payment_agent_tier_2')) return 2;
  if (window.roles.includes('payment_agent_tier_3')) return 3;
  if (window.roles.includes('payment_agent_tier_4')) return 4;
}

const schema = {
  types: [
    { type: "mbtc" },
    { type: "skrill" },
    { type: "credit_card" },
    { type: "neteller" },
    { type: "interac_etransfer" },
    { type: "ecopayz" },
    { type: "muchbetter" },
    { type: "bank_iban" },
    { type: "bank_domestic" },
    { type: "qiwi" },

    { type: "visa" },
    { type: "yandex" },
    { type: "bank_domestic_switch" },
  ],
  statuses: [
    { status: "created" },
    { status: "processing" },
    { status: "pending_review" },
    { status: "waiting_input" },
    { status: "complete" },
    { status: "cancelled" },
    { status: "refunded" },
  ],
};

const ranks = ["gold", "platinum", "diamond", "diamond_2"];

export default class Withdrawals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      total_pages: 1,
      order: "id_desc",
      type: "",
      status: "",
      withdrawals: [],
      selected: {},
      tierSelected: null,
      startDate: moment().subtract(7, "days").toDate(),
      endDate: new Date(),
      id: null,
    };
  }

  componentDidMount() {
    this.fetchWithdrawals();
    this.handleActiveTab(suggestFirstSelectedTab());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pageChanged = this.state.page !== prevState.page;
    const filterChanged = this.state.type !== prevState.type;
    const statusChanged = this.state.status !== prevState.status;
    const tierChanged = this.state.tierSelected !== prevState.tierSelected;
    const rankChanged = this.state.rank !== prevState.rank;

    const startDate = this.state.startDate !== prevState.startDate;
    const endDate = this.state.endDate !== prevState.endDate;
    const idChanged = this.state.id !== prevState.id;

    if (
      pageChanged ||
      filterChanged ||
      statusChanged ||
      tierChanged ||
      rankChanged ||
      startDate ||
      endDate ||
      idChanged
    ) {
      this.fetchWithdrawals();
    }
  }

  async fetchWithdrawals() {
    this.setState({ isLoading: true });
    try {
      const dateStart = this.state.startDate.toISOString();
      const dateEnd = this.state.endDate.toISOString();

      if (this.state.tierSelected || this.state.rank) {
        const { records, meta } = await window.api.get(
          "/admin/payments/withdrawals/pix",
          {
            params: {
              page: this.state.page,
              type: this.state.type,
              status: this.state.status,
              tier: this.state.tierSelected,
              rank: this.state.rank,
              id: this.state.id || null,
              dateStart,
              dateEnd,
            },
          }
        );
        this.setState({ total_pages: meta.total_pages, withdrawals: records });
      }
    } catch (err) {
      toast.error(err.message);
    }
    this.setState({ isLoading: false });
  }

  async editWithdrawal(id, data, password) {
    try {
      this.setState({ actionLoading: true });
      await window.api({
        method: "put",
        url: `/admin/payments/withdrawals/${id}`,
        data: data,
        headers: {
          "x-admin-password": password,
        },
      });
      await this.fetchWithdrawals();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  async approveAll() {
    try {
      let selectedIds = Object.keys(this.state.selected).filter(
        (id) => this.state.selected[id]
      );

      if (selectedIds.length <= 0) {
        return;
      }

      const password = ""; /*window.password_prompt();
      if (password === null) return;*/

      this.setState({ actionLoading: true });
      const res = await window.api({
        method: "post",
        url: `/admin/payments/withdrawals/approve_all`,
        data: {
          ids: selectedIds,
        },
        headers: {
          "x-admin-password": password,
        },
      });

      console.log("PIX_WITHDRAWALS_BULK_APPROVE_REPORT", res);

      toast.info(
        `Request completed with ${res.approved.length} approved and ${res.failed.length} failed. Check console log for more info.`
      );

      await this.fetchWithdrawals();
    } catch (err) {
      toast.error(err.message);
    }

    this.setState({ actionLoading: false });
  }

  async refundAll() {
    try {
      const selectedIds = Object.keys(this.state.selected).filter(
        (id) => this.state.selected[id]
      );

      if (selectedIds.length <= 0) {
        return;
      }

      const ACCEPTED_STATUS = 'waiting_input';

      const invalidWithdrawals = this.state.withdrawals.filter(
        (withdrawal) => 
          selectedIds.includes(withdrawal.id.toString()) && 
          withdrawal.status !== ACCEPTED_STATUS
      );

      if(invalidWithdrawals.length > 0){
        toast.error(`Only withdrawals with status "${ACCEPTED_STATUS}" can be refunded.`);
        return;
      }

      const password = "";

      this.setState({ actionLoading: true });
      const res = await window.api({
        method: "post",
        url: `/admin/payments/withdrawals/refund_all`,
        data: {
          ids: selectedIds,
          is_pix: true,
        },
        headers: {
          "x-admin-password": password,
        },
      });

      console.log("PIX_WITHDRAWALS_BULK_REFUND_REPORT", res);

      toast.info(
        `Request completed with ${res.refunded.length} refunded and ${res.failed.length} failed. Check console log for more info.`
      );

      await this.fetchWithdrawals();
    } catch (err) {
      // Service errors (which have a 'code' property) already display toast messages before reaching this catch block.
      // We only need to display error messages here for errors from other sources (those without a 'code' property).
      if(!err.code){
        toast.error(err.message);
      }
    }

    this.setState({ actionLoading: false });
  }

  // tier1: {
  //   min: 0,
  //   max: 499.99,
  // },
  // tier2: {
  //   min: 500,
  //   max: 2499.99,
  // },
  // tier3: {
  //   min: 2500,
  //   max: 9999.99,
  // },
  // tier4: {
  //   min: 10000,
  //   max: Number.MAX_VALUE,
  // },

  handleActiveTab = (index) => {
    if (typeof index === "string") {
      this.setState({ rank: index });
      this.setState({ tierSelected: null });
      return;
    }
    this.setState({ rank: null });
    if (index !== this.state.tierSelected) {
      this.setState({ page: 1 });
      this.setState({ tierSelected: index });
    } else {
      this.setState({ tierSelected: null });
    }
  };

  render() {
    const loading = false;

    const hasTierOneAccess = currentUserHasRole('payment_agent') || currentUserHasRole('payment_agent_tier_1') || currentUserHasRole('admin');

    return (
      <div
        className="withdrawals"
        style={{ position: "relative", "min-height": "500px" }}
      >
        {this.state.isLoading && (
          <div className={"loading-overlay"}>
            <h2>Searching, please wait ...</h2>
            <div className="loader" />
          </div>
        )}
        <div className="row">
          <div className="tabs-tier">
            {hasTierOneAccess && (
              <button
                onClick={() => this.handleActiveTab(1)}
                className={`tier-button ${
                  this.state.tierSelected === 1 ? "tier-button--active" : ""
                }`}
              >
                Tier 1
              </button>
            )}
            <PermissionsFragment
              feature={
                permissionsConstants.WITHDRAWALS_VIEW_PIX_LIST_OVER_TIER_1
              }
              fallbackComponent={[2, 3, 4].map((tier) => (
                <PermissionsFragment
                  key={tier}
                  feature={permissionsConstants[`PIX_WITHDRAWALS_TIER_${tier}`]}
                >
                  <button
                    onClick={() => this.handleActiveTab(tier)}
                    className={`tier-button ${
                      this.state.tierSelected === tier
                        ? "tier-button--active"
                        : ""
                    }`}
                  >
                    Tier {tier}
                  </button>
                </PermissionsFragment>
              ))}
            >
              {[2, 3, 4].map((tier) => {
                return (
                  <button
                    onClick={() => this.handleActiveTab(tier)}
                    className={`tier-button ${
                      this.state.tierSelected === tier
                        ? "tier-button--active"
                        : ""
                    }`}
                  >
                    Tier {tier}
                  </button>
                );
              })}
            </PermissionsFragment>
            {ranks.map((rank) => {
              return (
                <button
                  onClick={() => this.handleActiveTab(rank)}
                  className={`tier-button ${
                    this.state.rank === rank ? "tier-button--active" : ""
                  }`}
                >
                  {capitalizeFirstLetter(rank)}
                </button>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <h4>ID</h4>

            <input
              style={{ width: "100%" }}
              onChange={(e) => this.setState({ id: e.target.value, page: 1 })}
              value={this.state.id}
              type="number"
              data-testid="id-input"
            />
          </div>
          <div className="col-md-4">
            <h4>Status</h4>
            <Select
              name="type"
              value={this.state.status}
              onChange={(option) => {
                this.setState({ status: option.value });
              }}
              options={schema.statuses.map((val) => {
                return { value: val.status, label: val.status };
              })}
            />
          </div>
          <div
            style={{ width: "250px", marginTop: "60px", marginLeft: "40px" }}
          >
            <WrapperCalendar
              setStart={(date) => this.setState({ startDate: date })}
              setEnd={(date) => this.setState({ endDate: date })}
            />
          </div>
        </div>

        <div className={"pix-controller"}>
          <button
            onClick={() => {
              let selected = {};
              this.state.withdrawals.forEach((r) => {
                selected[r.id] = true;
              });

              this.setState({
                selected,
              });
            }}
          >
            Select All
          </button>

          <button
            onClick={() => {
              let selected = {};
              this.state.withdrawals.forEach((r) => {
                selected[r.id] = false;
              });

              this.setState({
                selected,
              });
            }}
          >
            Deselect All
          </button>

          <button
            onClick={() => {
              this.approveAll();
            }}
          >
            Approve Selected
          </button>
          <button
            onClick={() => {
              this.refundAll();
            }}
          >
            Refund Selected
          </button>
        </div>

        <br />
        <br />
        <br />

        <Table>
          <Thead>
            <Tr>
              <Th>Select</Th>
              <Th>Date</Th>
              <Th>ID</Th>
              <Th>User</Th>
              <Th>Type</Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
              <Th>Note</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.state.withdrawals.map((r, i) => {
              return (
                <Tr key={i}>
                  <Td>
                    <input
                      type={"checkbox"}
                      checked={this.state.selected[r.id]}
                      onClick={(e) => {
                        this.setState({
                          selected: {
                            ...this.state.selected,
                            [r.id]: e.target.checked,
                          },
                        });
                      }}
                    />
                  </Td>
                  <Td>{format.withMinutes(r.created_at)}</Td>

                  <Td>
                    <Link to={`/payments/withdrawals/${r.id}`}>{r.id}</Link>
                  </Td>
                  <Td>
                    <Link to={`/users/${r.user.id}`}>{r.user.username}</Link>
                  </Td>
                  <Td>
                    <Link to={`/payments/withdrawals/${r.id}`}>{r.type}</Link>
                  </Td>
                  <Td>
                    {Math.abs(r.amount)} {r.currency_type}
                  </Td>
                  <Td>{r.status}</Td>
                  <Td>
                    {["pending_review"].includes(r.status) && (
                      <div>
                        {r.status === "pending_review" && (
                          <button
                            className="small approve-button"
                            disabled={loading}
                            onClick={() => {
                              this.editWithdrawal(r.id, {
                                status: "processing",
                                type: r.type,
                              }).then(() => this.fetchWithdrawals());
                            }}
                          >
                            {loading ? "Loading..." : "Approve"}
                          </button>
                        )}

                        {["pending_review", "created"].includes(r.status) && (
                          <button
                            className="small"
                            disabled={loading}
                            onClick={() =>
                              this.editWithdrawal(r.id, {
                                status: "refunded",
                              }).then(() => this.fetchWithdrawals())
                            }
                          >
                            {loading ? "Loading..." : "Refund"}
                          </button>
                        )}
                      </div>
                    )}
                  </Td>
                  <Td>{r.note}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.state.total_pages}
          update={(page) => {
            this.setState({ page });
          }}
        />
      </div>
    );
  }
}
