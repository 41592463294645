const CASES_UPDATE = 'rewards/UPDATE';
const CASE_ITEMS_UPDATE = 'rewards/ITEMS/UPDATE';
const CASE_UPDATE = 'rewards/CASE/UPDATE';
const CASE_PRICE_UPDATE = 'rewards/PRICE/UPDATE';
const CASE_MODAL_STATUS_UPDATE = 'rewards/CASE_MODAL_STATUS/UPDATE'
const USER_RANKS_UPDATE = 'rewards/USER_RANKS_UPDATE';
const ADD_CURRENCY_RATE = 'rewards/ADD_CURRENCY_RATE';
const DELETE_CURRENCY_RATE = 'rewards/DELETE_CURRENCY_RATE';
const UPDATE_CURRENCY_RATE = 'rewards/UPDATE_CURRENCY_RATE';
const LOAD_CURRENCY_RATES = 'rewards/LOAD_CURRENCY_RATES';

export {
  CASES_UPDATE,
  CASE_ITEMS_UPDATE,
  CASE_UPDATE,
  CASE_PRICE_UPDATE,
  USER_RANKS_UPDATE,
  ADD_CURRENCY_RATE,
  DELETE_CURRENCY_RATE,
  UPDATE_CURRENCY_RATE,
  LOAD_CURRENCY_RATES,
  CASE_MODAL_STATUS_UPDATE,
};
