import { batch } from "react-redux";
import * as types from "./reward-types";
import { toast } from "react-toastify";

export const updateBoxes = () => {
  return (dispatch) => {
    window.api.get("/admin/rewards/mystery_boxes").then((data) => {
      dispatch({
        type: types.CASES_UPDATE,
        payload: data,
      });
    });
  };
};

export const calculatePrice = (props) => {
  return (dispatch) => {
    window.api
      .post("/admin/rewards/mystery_boxes/price", props)
      .then((data) => {
        dispatch({
          type: types.CASE_PRICE_UPDATE,
          payload: data,
        });
      })
      .catch((err) => {});
  };
};

export const updateBox = (id) => {
  return (dispatch) => {
    window.api.get("/admin/rewards/mystery_boxes/" + id).then((data) => {
      dispatch({
        type: types.CASE_UPDATE,
        payload: data,
      });
    });
  };
};

export const updateRewards = (page = 1, all = 0, name, id) => {
  return (dispatch) => {
    let url = `/admin/rewards?page=${page}&all=${all}`

    if (name) url += `&name=${name}`
    if (id) url += `&id=${id}`

    window.api.get(url).then((data) => {
      dispatch({
        type: types.CASE_ITEMS_UPDATE,
        payload: data,
      });
    });
  };
};

export const createBox = (props) => {
  return async (dispatch) => {
    props.image_url = await window.api.upload(props.file, "mystery-boxes");
    delete props.file;

    window.api.post("/admin/rewards/mystery_boxes", props).then((data) => {
      toast.success(`Mystery box created (${data.id}).`);

      dispatch(updateBoxes());
    });
  };
};

export const updateBoxRewards = (id, props, redirect) => {
  return (dispatch) => {
    window.api.put("/admin/rewards/mystery_boxes/" + id, props).then(async (data) => {
      toast.success(`Mystery box updated creating new mystery box with id ${data.id}.`);
      batch(() => {
        dispatch(updateBox(data.id))
        dispatch({ type: types.CASE_MODAL_STATUS_UPDATE, payload: false });
      });
      redirect(data.id);
    });
  };
};

export const deleteBox = (id) => {
  return (dispatch) => {
    window.api.delete("/admin/rewards/mystery_boxes/" + id).then((data) => {
      dispatch(updateBoxes());
    });
  };
};

export const createReward = (props) => {
  return async (dispatch) => {
    props.image_url = await window.api.upload(props.file, "rewards");
    delete props.file;

    if (
      ["softswiss_free_spins", "game_integration_free_spins"].includes(
        props.type
      )
    ) {
      return window.api
        .post("/admin/softswiss-free-spins/create-reward", props)
        .then((data) => {
          toast.success(`Reward created (${data?.id}).`);
          return dispatch(updateRewards());
        });
    }

    window.api.post("/admin/rewards", props).then((data) => {
      toast.success(`Reward created (${data?.id}).`);

      dispatch(updateRewards());
    });
  };
};
export const deleteReward = (id) => {
  return async (dispatch) => {
    window.api.delete(`/admin/rewards/${id}`).then((data) => {
      toast.success(`Reward id #${id} deleted.`);
      dispatch(updateRewards());
    });
  };
};
export const updateUserRanks = () => {
  return (dispatch) => {
    window.api.get("/admin/rewards/user_ranks").then((data) => {
      dispatch({
        type: types.USER_RANKS_UPDATE,
        payload: data,
      });
    });
  };
};
