module.exports.permissionsConstants = {
  /* permissions.json */
  AFFILIATES_VIEW_LIST: "AFFILIATES_VIEW_LIST",
  AFFILIATES_VIEW_SYSTEM: "AFFILIATES_VIEW_SYSTEM",
  AFFILIATES_VIEW_LINK: "AFFILIATES_VIEW_LINK",
  AFFILIATES_CREATE_LINK: "AFFILIATES_CREATE_LINK",
  CHAT_BAN_USER: "CHAT_BAN_USER",
  CHAT_DELETE_MESSAGE: "CHAT_DELETE_MESSAGE",
  KYC_VIEW_LIST: "KYC_VIEW_LIST",
  KYC_UPDATE_VERIFICATION: "KYC_UPDATE_VERIFICATION",
  USER_KYC_VIEW_LIST: "USER_KYC_VIEW_LIST",
  USER_KYC_CREATE_VERIFICATION: "USER_KYC_CREATE_VERIFICATION",
  USER_KYC_UPDATE_VERIFICATION: "USER_KYC_UPDATE_VERIFICATION",
  USER_KYC_SEND_NOTIFICATION: "USER_KYC_SEND_NOTIFICATION",
  USER_DOCUMENTS_VIEW_FILE: "USER_DOCUMENTS_VIEW_FILE",
  USER_DOCUMENTS_VIEW_LIST: "USER_DOCUMENTS_VIEW_LIST",
  DEPOSITS_VIEW_LIST: "DEPOSITS_VIEW_LIST",
  DEPOSITS_CREDIT_DEPOSIT: "DEPOSITS_CREDIT_DEPOSIT",
  DEPOSITS_CREDIT_FAKE: "DEPOSITS_CREDIT_FAKE",
  USER_DEPOSITS_VIEW_LIST: "USER_DEPOSITS_VIEW_LIST",
  WITHDRAWALS_VIEW_LIST: "WITHDRAWALS_VIEW_LIST",
  WITHDRAWALS_VIEW_PIX_LIST: "WITHDRAWALS_VIEW_PIX_LIST",
  WITHDRAWALS_VIEW_PIX_LIST_OVER_TIER_1: "WITHDRAWALS_VIEW_PIX_LIST_OVER_TIER_1",
  WITHDRAWALS_VIEW_STUCK_LIST: "WITHDRAWALS_VIEW_STUCK_LIST",
  WITHDRAWALS_VIEW_WITHDRAWAL: "WITHDRAWALS_VIEW_WITHDRAWAL",
  WITHDRAWALS_CREATE_FRAUD_CHECK: "WITHDRAWALS_CREATE_FRAUD_CHECK",
  WITHDRAWALS_UPDATE_WITHDRAWAL: "WITHDRAWALS_UPDATE_WITHDRAWAL",
  WITHDRAWALS_RETRY_WITHDRAWAL: "WITHDRAWALS_RETRY_WITHDRAWAL",
  WITHDRAWALS_VIEW_REFUNDABLE_DEPOSITS_LIST: "WITHDRAWALS_VIEW_REFUNDABLE_DEPOSITS_LIST",
  WITHDRAWALS_REFUND_DEPOSIT_FOR_WITHDRAWAL: "WITHDRAWALS_REFUND_DEPOSIT_FOR_WITHDRAWAL",
  WITHDRAWALS_VIEW_LITE: "WITHDRAWALS_VIEW_LITE",
  USER_WITHDRAWALS_VIEW_LIST: "USER_WITHDRAWALS_VIEW_LIST",
  PAYMENT_PROVIDERS_VIEW_LIST: "PAYMENT_PROVIDERS_VIEW_LIST",
  PAYMENT_PROVIDERS_VIEW_PROVIDER: "PAYMENT_PROVIDERS_VIEW_PROVIDER",
  PAYMENT_PROVIDERS_UPDATE_PROVIDER: "PAYMENT_PROVIDERS_UPDATE_PROVIDER",
  PAYMENT_METHODS_VIEW_LIST: "PAYMENT_METHODS_VIEW_LIST",
  PAYMENT_COUNTRY_ORDERING_VIEW_LIST: "PAYMENT_COUNTRY_ORDERING_VIEW_LIST",
  PAYMENT_COUNTRY_ORDERING_CREATE_ORDERING: "PAYMENT_COUNTRY_ORDERING_CREATE_ORDERING",
  PAYMENT_COUNTRY_ORDERING_VIEW_ORDERING: "PAYMENT_COUNTRY_ORDERING_VIEW_ORDERING",
  PAYMENT_COUNTRY_ORDERING_UPDATE_ORDERING: "PAYMENT_COUNTRY_ORDERING_UPDATE_ORDERING",
  PAYMENT_ACCOUNTS_CREATE_INFLUENCER_CARD: "PAYMENT_ACCOUNTS_CREATE_INFLUENCER_CARD",
  USER_REFERRAL_VIEW: "USER_REFERRAL_VIEW",
  REWARDS_VIEW_LIST: "REWARDS_VIEW_LIST",
  REWARDS_VIEW_REWARD: "REWARDS_VIEW_REWARD",
  REWARDS_CREATE_REWARD: "REWARDS_CREATE_REWARD",
  REWARDS_UPDATE_REWARD: "REWARDS_UPDATE_REWARD",
  PROCESS_CASHBACK_REWARDS: "PROCESS_CASHBACK_REWARDS",
  USER_REWARDS_INVENTORY_VIEW_LIST: "USER_REWARDS_INVENTORY_VIEW_LIST",
  USER_REWARDS_INVENTORY_ADD_REWARD: "USER_REWARDS_INVENTORY_ADD_REWARD",
  USER_REWARDS_INVENTORY_UPDATE_REWARD: "USER_REWARDS_INVENTORY_UPDATE_REWARD",
  RANKS_VIEW_LIST: "RANKS_VIEW_LIST",
  RANKS_UPDATE_RANK: "RANKS_UPDATE_RANK",
  GLOBAL_XP_VIEW_BONUS: "GLOBAL_XP_VIEW_BONUS",
  GLOBAL_XP_UPDATE_BONUS: "GLOBAL_XP_UPDATE_BONUS",
  GLOBAL_XP_DISABLE_BONUS: "GLOBAL_XP_DISABLE_BONUS",
  PROMO_CODES_VIEW_LIST: "PROMO_CODES_VIEW_LIST",
  PROMO_CODES_CREATE_CODE: "PROMO_CODES_CREATE_CODE",
  PROMO_CODES_VIEW_LIST_REDEEMS: "PROMO_CODES_VIEW_LIST_REDEEMS",
  PROMO_CODES_VIEW_CODE: "PROMO_CODES_VIEW_CODE",
  PROMO_CODES_UPDATE_CODE: "PROMO_CODES_UPDATE_CODE",
  MYSTERY_BOXES_VIEW_LIST: "MYSTERY_BOXES_VIEW_LIST",
  MYSTERY_BOXES_CALCULATE_PRICE: "MYSTERY_BOXES_CALCULATE_PRICE",
  MYSTERY_BOXES_CREATE_BOX: "MYSTERY_BOXES_CREATE_BOX",
  MYSTERY_BOXES_UPDATE_BOX: "MYSTERY_BOXES_UPDATE_BOX",
  MYSTERY_BOXES_VIEW_BOX: "MYSTERY_BOXES_VIEW_BOX",
  MYSTERY_BOXES_REWARD_TIERS_VIEW_LIST: "MYSTERY_BOXES_REWARD_TIERS_VIEW_LIST",
  MYSTERY_BOXES_REWARD_TIERS_VIEW_TIER: "MYSTERY_BOXES_REWARD_TIERS_VIEW_TIER",
  MYSTERY_BOXES_REWARD_TIERS_CREATE_TIER: "MYSTERY_BOXES_REWARD_TIERS_CREATE_TIER",
  MYSTERY_BOXES_REWARD_TIERS_UPDATE_TIER: "MYSTERY_BOXES_REWARD_TIERS_UPDATE_TIER",
  USER_MYSTERY_BOXES_VIEW_LIST_OPENS: "USER_MYSTERY_BOXES_VIEW_LIST_OPENS",
  USER_MYSTERY_BOXES_VIEW_LIST_TRANSACTIONS: "USER_MYSTERY_BOXES_VIEW_LIST_TRANSACTIONS",
  USER_MYSTERY_BOXES_VIEW_LIST_BALANCES: "USER_MYSTERY_BOXES_VIEW_LIST_BALANCES",
  BONUSES_VIEW_LIST: "BONUSES_VIEW_LIST",
  BONUSES_CREATE_BONUS: "BONUSES_CREATE_BONUS",
  BONUSES_UPDATE_BONUS: "BONUSES_UPDATE_BONUS",
  USER_BONUS_INVENTORY_VIEW: "USER_BONUS_INVENTORY_VIEW",
  USER_BONUS_CONSUMED_BONUSES_VIEW: "USER_BONUS_CONSUMED_BONUSES_VIEW",
  USER_BONUSES_UPDATE_ACTIVATION: "USER_BONUSES_UPDATE_ACTIVATION",
  BONUS_ROUND_WELCOME_PACKAGE_VIEW_LIST: "BONUS_ROUND_WELCOME_PACKAGE_VIEW_LIST",
  BONUS_ROUND_WELCOME_PACKAGE_CREATE: "BONUS_ROUND_WELCOME_PACKAGE_CREATE",
  BONUS_ROUND_WELCOME_PACKAGE_UPDATE: "BONUS_ROUND_WELCOME_PACKAGE_UPDATE",
  USER_VIEW_LIST: "USER_VIEW_LIST",
  USER_VIEW_USER: "USER_VIEW_USER",
  CHECK_CPF: "CHECK_CPF",
  USER_UPDATE_SUSPENSION: "USER_UPDATE_SUSPENSION",
  USER_CANCEL_SUSPENSION: "USER_CANCEL_SUSPENSION",
  USER_CLOSE_ACCOUNT: "USER_CLOSE_ACCOUNT",
  USER_UPDATE_WALLET_BALANCE: "USER_UPDATE_WALLET_BALANCE",
  USER_UPDATE_RANK: "USER_UPDATE_RANK",
  USER_UPDATE_ROLES: "USER_UPDATE_ROLES",
  USER_UPDATE_LABEL: "USER_UPDATE_LABEL",
  USER_UPDATE_BAN: "USER_UPDATE_BAN",
  USER_ADD_ROLE: "USER_ADD_ROLE",
  USER_REMOVE_ROLE: "USER_REMOVE_ROLE",
  USER_VIEW_LIST_PERMISSIONS: "USER_VIEW_LIST_PERMISSIONS",
  USER_UPDATE_PERMISSIONS: "USER_UPDATE_PERMISSIONS",
  USER_REMOVE_PERMISSIONS: "USER_REMOVE_PERMISSIONS",
  USER_UPDATE_EMAIL: "USER_UPDATE_EMAIL",
  USER_UPDATE_KYC_INFO: "USER_UPDATE_KYC_INFO",
  USER_KYC_UPLOAD_FILE: "USER_KYC_UPLOAD_FILE",
  USER_UPDATE: "USER_UPDATE",
  USER_CREATE: "USER_CREATE",
  USER_ENABLE_TWO_FACTOR: "USER_ENABLE_TWO_FACTOR",
  USER_ACTIVATE_TWO_FACTOR: "USER_ACTIVATE_TWO_FACTOR",
  USER_TRANSACTIONS_VIEW_LIST: "USER_TRANSACTIONS_VIEW_LIST",
  USER_FINGERPRINTS_VIEW_LIST: "USER_FINGERPRINTS_VIEW_LIST",
  USER_NOTES_VIEW_LIST: "USER_NOTES_VIEW_LIST",
  USER_NOTES_CREATE: "USER_NOTES_CREATE",
  ANNOUNCEMENTS_VIEW_LIST: "ANNOUNCEMENTS_VIEW_LIST",
  ANNOUNCEMENTS_CREATE: "ANNOUNCEMENTS_CREATE",
  ANNOUNCEMENTS_DISABLE: "ANNOUNCEMENTS_DISABLE",
  SETTINGS_VIEW_LIST: "SETTINGS_VIEW_LIST",
  SETTINGS_UPDATE: "SETTINGS_UPDATE",
  UPLOADS_CREATE_FILE: "UPLOADS_CREATE_FILE",
  REPORTS_USER_FINANCIALS: "REPORTS_USER_FINANCIALS",
  REPORTS_USER_ANTI_FRAUD: "REPORTS_USER_ANTI_FRAUD",
  REPORTS_WITHDRAWAL: "REPORTS_WITHDRAWAL",
  GAMES_ENABLE_GAME: "GAMES_ENABLE_GAME",
  GAMES_DISABLE_GAME: "GAMES_DISABLE_GAME",
  GAMES_CHANGE_IMAGE_URL: "GAMES_CHANGE_IMAGE_URL",
  GAME_PROVIDERS_ENABLE_PROVIDER: "GAME_PROVIDERS_ENABLE_PROVIDER",
  GAME_PROVIDERS_MOVE_GAME_TO_NEW_PROVIDER: "GAME_PROVIDERS_MOVE_GAME_TO_NEW_PROVIDER",
  GAME_PROVIDERS_CHANGE_SLUG: "GAME_PROVIDERS_CHANGE_SLUG",
  GAME_PROVIDERS_CHANGE_GAME_NAME: "GAME_PROVIDERS_CHANGE_GAME_NAME",
  GAME_PROVIDERS_CHANGE_RTP: "GAME_PROVIDERS_CHANGE_RTP",
  GAME_PROVIDERS_DISABLE_PROVIDER: "GAME_PROVIDERS_DISABLE_PROVIDER",
  GAME_CATEGORIES_CREATE_CATEGORY: "GAME_CATEGORIES_CREATE_CATEGORY",
  GAME_CATEGORIES_ADD_GAME: "GAME_CATEGORIES_ADD_GAME",
  GAME_CATEGORIES_REMOVE_GAME: "GAME_CATEGORIES_REMOVE_GAME",
  GAME_PROVIDER_VIEW: "GAME_PROVIDER_VIEW",
  GAME_VIEW: "GAME_VIEW",
  GAME_VIEW_LIST: "GAME_VIEW_LIST",
  GAME_PROVIDERS_VIEW: "GAME_PROVIDERS_VIEW",
  GAME_CATEGORY_VIEW: "GAME_CATEGORY_VIEW",
  VIEW_SEARCH_TERMS: "VIEW_SEARCH_TERMS",
  GAMES_VIEW: "GAMES_VIEW",
  GAMES_SEARCH: "GAMES_SEARCH",
  REQUEST_USER_UPDATE_EMAIL: "REQUEST_USER_UPDATE_EMAIL",
  REQUEST_DEPOSITS_CREDIT_DEPOSIT: "REQUEST_DEPOSITS_CREDIT_DEPOSIT",
  REQUEST_USER_UPDATE_WALLET_BALANCE: "REQUEST_USER_UPDATE_WALLET_BALANCE",
  REQUEST_USER_UPDATE_KYC_INFO: "REQUEST_USER_UPDATE_KYC_INFO",
  REQUEST_REFUND: "REQUEST_REFUND",
  APPROVE_REFUND: "APPROVE_REFUND",
  DENY_REFUND: "DENY_REFUND",
  VIEW_REFUNDS: "VIEW_REFUNDS",
  USER_PAYMENT_ACCOUNTS_UPDATE: "USER_PAYMENT_ACCOUNTS_UPDATE",
  USER_UPDATE_CUSTOMER_INFO: "USER_UPDATE_CUSTOMER_INFO",
  WITHDRAWALS_BULK_APPROVAL: "WITHDRAWALS_BULK_APPROVAL",
  WITHDRAWALS_BULK_REFUND: "WITHDRAWALS_BULK_REFUND",
  WITHDRAWALS_EDIT_PSP_AMOUNT: "WITHDRAWALS_EDIT_PSP_AMOUNT",
  WITHDRAWALS_COMMANDS: "WITHDRAWALS_COMMANDS",
  APPROVE_REQUEST: "APPROVE_REQUEST",
  DENY_REQUEST: "DENY_REQUEST",
  VIEW_REQUESTS: "VIEW_REQUESTS",
  GAME_CATEGORIES_VIEW_LIST: "GAME_CATEGORIES_VIEW_LIST",
  GAME_SEARCH_TERMS_LOCK: "GAME_SEARCH_TERMS_LOCK",
  GAME_SEARCH_TERMS_UNLOCKED: "GAME_SEARCH_TERMS_UNLOCKED",
  GAME_SEARCH_TERMS_PUSH_UNLOCKED: "GAME_SEARCH_TERMS_PUSH_UNLOCKED",
  GAME_CATEGORIES_CHANGE_NAME: "GAME_CATEGORIES_CHANGE_NAME",
  SOFTSWISS_FREE_SPINS_VIEW_LIST: "SOFTSWISS_FREE_SPINS_VIEW_LIST",
  REWARDS_SOFTSWISS_CREATE_REWARD: "REWARDS_SOFTSWISS_CREATE_REWARD",
  USER_VIEW_CRASH_BETS: "USER_VIEW_CRASH_BETS",
  USER_VIEW_ROULETTE_BETS: "USER_VIEW_ROULETTE_BETS",
  SOFTSWISS_ISSUE_SPINS: "SOFTSWISS_ISSUE_SPINS",
  SOFTSWISS_CANCEL_SPINS: "SOFTSWISS_CANCEL_SPINS",
  SOFTSWISS_PROCESS_SPINS: "SOFTSWISS_PROCESS_SPINS",
  GAME_PROVIDERS_DISABLE_DEMO: "GAME_PROVIDERS_DISABLE_DEMO",
  LOYALTY_CREDIT: "LOYALTY_CREDIT",
  LOYALTY_CALCULATE: "LOYALTY_CALCULATE",
  CREDIT_BONUS_ROUNDS: "CREDIT_BONUS_ROUNDS",
  CREDIT_MYSTERY_BOX_SPINS: "CREDIT_MYSTERY_BOX_SPINS",
  RACE_CREATE: "RACE_CREATE",
  RACE_PRIZE_CREATE: "RACE_PRIZE_CREATE",
  RACE_UPDATE: "RACE_UPDATE",
  RACE_DELETE: "RACE_DELETE",
  RACE_PRIZE_UPDATE: "RACE_PRIZE_UPDATE",
  RACE_PRIZES_GET: "RACE_PRIZES_GET",
  WEEKLY_CASHBACK_RETRY: "WEEKLY_CASHBACK_RETRY",
  RAFFLE_CALCULATE_TOTAL_TICKETS: "RAFFLE_CALCULATE_TOTAL_TICKETS",
  RAFFLE_CALCULATE_WINNING_USER: "RAFFLE_CALCULATE_WINNING_USER",
  RAFFLE_VIEW_LIST: "RAFFLE_VIEW_LIST",
  USER_GET_FINANCIALS: "USER_GET_FINANCIALS",
  BONUSES_VIEW_BONUS: "BONUSES_VIEW_BONUS",
  USER_GET_ANTI_FRAUD: "USER_GET_ANTI_FRAUD",
  USER_REPAIR_WALLET: "USER_REPAIR_WALLET",
  USER_ROUTE_PERMISSION: "USER_ROUTE_PERMISSION",
  UPDATE_USER_ROUTE_PERMISSION: "UPDATE_USER_ROUTE_PERMISSION",
  USER_ROUTE_MAPPING: "USER_ROUTE_MAPPING",
  UPDATE_USER_ROUTE_MAPPING: "UPDATE_USER_ROUTE_MAPPING",
  GET_USER_ROUTE_PERMISSIONS: "GET_USER_ROUTE_PERMISSIONS",
  GET_USER_ROUTE_MAPPINGS: "GET_USER_ROUTE_MAPPINGS",
  USER_GET_REPAIRABLE_WALLETS: "USER_GET_REPAIRABLE_WALLETS",
  USER_UPDATE_KYC_LEVEL: "USER_UPDATE_KYC_LEVEL",
  WITHDRAWALS_VIEW_AUTOMATED_LIST: "WITHDRAWALS_VIEW_AUTOMATED_LIST",
  CLOSE_ROUND: "CLOSE_ROUND",

  /* refund-round-permissions.js  */
  REFUND_ROUND: "REFUND_ROUND",
  VIEW_GAME_PROVIDER_ROUND: "VIEW_GAME_PROVIDER_ROUND",
  USER_VIEW_GAME_PROVIDER_ROUNDS: "USER_VIEW_GAME_PROVIDER_ROUNDS",

  /* get-user-double-bets-permissions.js */
  //USER_VIEW_ROULETTE_BETS: "USER_VIEW_ROULETTE_BETS", REPEATED
  VIEW_ROULETTE_BET: "VIEW_ROULETTE_BET",

  /* '../user/get-user-crash-bets-permissions' */
  // "USER_VIEW_CRASH_BETS": "USER_VIEW_CRASH_BETS", REPEATED
  "VIEW_CRASH_BET": "VIEW_CRASH_BET",

  /* '../user/get-user-mines-bets-permissions'  */
  USER_VIEW_MINES_BETS: "USER_VIEW_MINES_BETS",
  VIEW_MINES_BET: "VIEW_MINES_BET",

  /* '../user/get-user-dice-bets-permissions'  */
  USER_VIEW_DICE_BETS: "USER_VIEW_DICE_BETS",
  VIEW_DICE_BET: "VIEW_DICE_BET",

  /* '../casino/get-weekly-cashback-payouts-permissions' */
  LOYALTY_VIEW_LIST: "LOYALTY_VIEW_LIST",
  USER_LOYALTY_VIEW_LIST: "USER_LOYALTY_VIEW_LIST",

  /* '../user/get-timed-cashback-reward-details-permissions' */
  VIEW_CASHBACK_REWARD_DETAILS: "VIEW_CASHBACK_REWARD_DETAILS",


  /* '../user/get-user-payment-methods-permissions' */
  USER_PAYMENT_ACCOUNTS_VIEW_LIST: "USER_PAYMENT_ACCOUNTS_VIEW_LIST",

  /* '../user/get-bonus-round-transaction-history-permissions' */
  USER_BONUS_ROUNDS_VIEW_LIST: "USER_BONUS_ROUNDS_VIEW_LIST",
  USER_BONUS_ROUNDS_TRANSACTIONS_VIEW_LIST: "USER_BONUS_ROUNDS_TRANSACTIONS_VIEW_LIST",

  /* '../user/get-user-transactions-permissions' */
  // "USER_TRANSACTIONS_VIEW_LIST": "USER_TRANSACTIONS_VIEW_LIST", REPEATED

  /* './payment-permissions' */
  PAYMENT_METHODS_USER_BLACKLISTS_VIEW: "PAYMENT_METHODS_USER_BLACKLISTS_VIEW",
  PAYMENT_METHODS_USER_BLACKLISTS_CREATE: "PAYMENT_METHODS_USER_BLACKLISTS_CREATE",
  PAYMENT_METHODS_USER_BLACKLISTS_DISABLE: "PAYMENT_METHODS_USER_BLACKLISTS_DISABLE",
  PAYMENT_PLATFORMS_UPDATE_METHOD: "PAYMENT_PLATFORMS_UPDATE_METHOD",

  AFFILIATES_VIEW_AFFILIATE: 'AFFILIATES_VIEW_AFFILIATE',
  AFFILIATES_REFERRAL_VIEW: 'AFFILIATES_REFERRAL_VIEW',
  AFFILIATES_CAMPAIGNS_VIEW: 'AFFILIATES_CAMPAIGNS_VIEW',
  AFFILIATES_CAMPAIGNS_CREATE: 'AFFILIATES_CAMPAIGNS_CREATE',
  AFFILIATES_CAMPAIGNS_UPDATE: 'AFFILIATES_CAMPAIGNS_UPDATE',
  AFFILIATES_SET_MANAGER: 'AFFILIATES_SET_MANAGER',

  FLAG_GROUP_CREATE: 'FLAG_GROUP_CREATE',
  FLAG_GROUP_UPDATE: 'FLAG_GROUP_UPDATE',
  FLAG_GROUP_LIST: 'FLAG_GROUP_LIST',
  FLAG_GROUP_LIST_USERS: 'FLAG_GROUP_LIST_USERS',
  FLAG_GROUP_UPDATE_USER: 'FLAG_GROUP_UPDATE_USER',
  FLAG_GROUP_CREATE_USER: 'FLAG_GROUP_CREATE_USER',
  ADD_USERS_TO_GROUP: 'ADD_USERS_TO_GROUP',
  SET_USERS_TO_GROUP: 'SET_USERS_TO_GROUP',
  REMOVE_FEATURE_FLAG_GROUP_FROM_USER: 'REMOVE_FEATURE_FLAG_GROUP_FROM_USER',
  REMOVE_FEATURE_FLAG_GROUPS_FROM_USERS:
    'REMOVE_FEATURE_FLAG_GROUPS_FROM_USERS',
  SET_GROUPS_TO_USER: 'SET_GROUPS_TO_USER',
  SET_GROUPS_TO_USERS: 'SET_GROUPS_TO_USERS',
  SET_ENABLED_USER_FROM_GROUP: 'SET_ENABLED_USER_FROM_GROUP',
  DELETE_BULK_USERS_FROM_GROU: 'DELETE_BULK_USERS_FROM_GROUP',
  ADD_GROUPS_TO_USER: 'ADD_GROUPS_TO_USER',

  WAITLIST_FEATURE_BULK_GRANT_ACCESS: 'WAITLIST_FEATURE_BULK_GRANT_ACCESS',
  WAITLIST_FEATURE_VIEW_LIST: 'WAITLIST_FEATURE_VIEW_LIST',
  WAITLIST_FEATURE_VIEW: 'WAITLIST_FEATURE_VIEW',
  WAITLIST_FEATURE_UPDATE_STATUS: 'WAITLIST_FEATURE_UPDATE_STATUS',
  WAITLIST_FEATURE_CHANGE_USER_STATUS: 'WAITLIST_FEATURE_CHANGE_USER_STATUS',

  VIEW_CUSTOM_ANALYTICS: "VIEW_CUSTOM_ANALYTICS",
  VIEW_CUSTOM_ANALYTICS_ACTION_TYPES: 'VIEW_CUSTOM_ANALYTICS_ACTION_TYPES',
  STATUS_MONITORING: "STATUS_MONITORING",
  PIX_WITHDRAWALS_TIER_2: "PIX_WITHDRAWALS_TIER_2",
  PIX_WITHDRAWALS_TIER_3: "PIX_WITHDRAWALS_TIER_3",
  PIX_WITHDRAWALS_TIER_4: "PIX_WITHDRAWALS_TIER_4",

  USER_OTP_VIEW_LIST: 'USER_OTP_VIEW_LIST',
  USER_OTP_DELETE_PHONE: 'USER_OTP_DELETE_PHONE'
}