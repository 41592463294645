import React, { Component } from "react";
import { connect } from "react-redux";
import { updatePaymentProviders, editProvider } from "../actions";
import Pagination from "../../shared/pagination";

import { Link } from "react-router-dom";
import { format } from "../../shared/utils/dates";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { editPaymentMethodPlatform } from "../actions";
import { toast } from "react-toastify";
import { checkPermissionsGroup } from "app/api";
import permissionGroups from "app/permission-groups";
import { Tooltip } from "@mui/material";
import DownloadCSVButton from "shared/DownloadCSVButton";

class ProviderFinder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      processing: {},
      id: null,
      type: null,
      enabled: null,
      records: null,
    };
  }

  componentDidMount() {
    this.update();

    let records = [];
    let totalPages = 0;
    window.api.get("/admin/payments/providers").then((data) => {
      records = data.records;
      totalPages = data.meta.total_pages;

      if (totalPages > 1) {
        let promises = [];
        for (let index = 2; index < totalPages + 1; index++) {
          promises.push(
            window.api.get("/admin/payments/providers?page=" + index)
          );
        }

        Promise.all(promises).then((responses) => {
          responses.forEach((response) => {
            records = records.concat(response.records);
          });

          records.map((record) => {
            delete record.total_count;

            return record;
          });
          this.setState({ ...this.state, records });
        });
      } else {
        this.setState({ ...this.state, records });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id || prevState.type !== this.state.type || prevState.enabled !== this.state.enabled) {
      this.update();
    }
  }

  update() {
    this.props.update({
      page: this.state.page,
      id: this.state.id,
      type: this.state.type,
      enabled: this.state.enabled,
    });
  }

  handleEditProvider(paymentProvider, changedData) {
    this.props
      .edit(paymentProvider.id, changedData)
      .then(() => {
        this.update();
        toast.success(`${paymentProvider.type} updated`);
      })
      .catch((err) => toast.error(err.message));
  }

  handleChangePaymentPlatform(event, paymentProvider) {
    const newPlatform = event.target.value;

    if (!checkPermissionsGroup(permissionGroups.PAYMENTS.METHODS)) {
      return toast.error(
        `You don't have permissions to edit payment method platforms`
      );
    }

    if (
      newPlatform &&
      paymentProvider.payment_method.platform !== newPlatform
    ) {
      return this.props
        .editPaymentMethodPlatform(
          paymentProvider.payment_method.id,
          newPlatform
        )
        .then(() => {
          toast.success(`${paymentProvider.payment_method.type} updated`);
        })
        .catch((err) => toast.error(err.message));
    }

    return toast.error(
      `Something went wrong updating ${paymentProvider.payment_method.type}`
    );
  }

  render() {
    const { url } = this.props.match;
    return (
      <div>
        <div className="row" style={{ "margin-bottom": "50px" }}>
          <div className="col-md-3">
            <h4>ID</h4>

            <input
              style={{ width: "100%" }}
              onChange={(e) => this.setState({ id: e.target.value, page: 1 })}
              value={this.state.id}
              type="number"
            />
          </div>
          <div className="col-md-3">
            <h4>Type</h4>

            <input
              style={{ width: "100%" }}
              onChange={(e) => this.setState({ type: e.target.value, page: 1 })}
              value={this.state.type}
            />
          </div>
          <div 
            className="col-md-3"
            style={{ flexDirection: 'column', display: 'flex' }}
          >
            <h4>Status</h4>

            <select
              style={{ width: "100%", height: "100%" }}
              onChange={(e) => this.setState({ enabled: e.target.value, page: 1 })}
              value={this.state.enabled}
              data-testid="filter-select"
            >
              <option selected>All</option>
              <option value="1">enabled</option>
              <option value="0">disabled</option>
            </select>
          </div>

          <div className="col-md-3" style={{ display: "flex" }}>
            <DownloadCSVButton
              style={{ alignSelf: "flex-end" }}
              records={this.state.records}
            />
          </div>
        </div>
        <Table>
          <Thead>
            <Tr>
              <Th>Created</Th>
              <Th>ID</Th>
              <Th>Method Type</Th>
              <Th>Provider Type</Th>
              <Th>Payment Platform</Th>
              <Th>Processing Time</Th>
              <Th>Method</Th>
              <Th>Service</Th>
              <Th>Enabled</Th>
              <Th>Globally Available</Th>
              <Th>Supported Countries</Th>
              <Th>Excluded Countries</Th>
              <Th>Requires Deposit Account</Th>
              <Th>Requires Complete Deposit With Method</Th>
              <Th>KYC Requirement</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.props.records.map((r, i) => {
              return (
                <Tr key={`provider-${r.id}-${i}`}>
                  <Td>{format.withMinutes(r.created_at)}</Td>
                  <Td>
                    <Link to={`${url}/` + r.id}>{r.id}</Link>
                  </Td>
                  <Td>{r.payment_method.type}</Td>
                  <Td>
                    {r.type === "method_redirect"
                      ? `${r.type} (${r.method_redirect_type})`
                      : r.type}
                  </Td>
                  <Td>
                    <select
                      disabled={
                        !checkPermissionsGroup(
                          permissionGroups.PAYMENTS.METHODS
                        )
                      }
                      onChange={(event) =>
                        this.handleChangePaymentPlatform(event, r)
                      }
                      defaultValue={r.payment_method.platform}
                    >
                      <option
                        selected={!r.payment_method.platform}
                        disabled={true}
                      ></option>
                      {this.props.platforms.map((platform) => (
                        <option value={platform}>{platform}</option>
                      ))}
                    </select>
                  </Td>
                  <Td>
                    <input
                      value={
                        this.state.processing[r.id] === undefined
                          ? r.processing_time
                          : this.state.processing[r.id]
                      }
                      onChange={(el) => {
                        this.setState({
                          processing: {
                            ...this.state.processing,
                            [r.id]:
                              el.target.value === "" ? null : el.target.value,
                          },
                        });
                      }}
                    />

                    {r.processing_time !== this.state.processing[r.id] &&
                      this.state.processing[r.id] !== undefined && (
                        <button
                          onClick={() =>
                            this.handleEditProvider(r, {
                              processing_time: this.state.processing[r.id],
                            })
                          }
                        >
                          Save
                        </button>
                      )}
                  </Td>
                  <Td>{r.method}</Td>
                  <Td>{r.service}</Td>
                  <Td>
                    <a
                      onClick={() =>
                        this.handleEditProvider(r, {
                          enabled: !r.enabled,
                        })
                      }
                    >
                      {r.enabled ? "Disable" : "Enable"}
                    </a>
                  </Td>
                  <Td>
                    {r.globally_available ? (
                      <i className="fas fa-check" />
                    ) : null}
                  </Td>
                  <Td>{r.supported_countries.toString()}</Td>
                  <Td>{r.excluded_countries.toString()}</Td>
                  <Td>
                    <Tooltip
                      title={`The user will need to have at least one payment_account saved for ${r.payment_method.type} to be able to withdraw with it. When the user deposits using this method, should be saved the user account to be used here, if there are not saved accounts doesn't matter how many deposits the user has.`}
                    >
                      <button
                        className={`small ${
                          !r.requires_deposit_account ? "outlined" : ""
                        }`}
                        onClick={() =>
                          this.handleEditProvider(r, {
                            requires_deposit_account:
                              !r.requires_deposit_account,
                          })
                        }
                      >
                        {r.requires_deposit_account ? (
                          <i className="fas fa-check" />
                        ) : (
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        )}
                      </button>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Tooltip
                      title={`User will need to perform one deposit using ${
                        r.payment_method?.platform
                          ? r.payment_method.platform + " platform or"
                          : ""
                      } ${
                        r.payment_method.type
                      } method before of be able to withdraw with this method. Any deposit will count if meets with the above condition.`}
                    >
                      <button
                        className={`small ${
                          !r.requires_complete_deposit ? "outlined" : ""
                        }`}
                        onClick={() =>
                          this.handleEditProvider(r, {
                            requires_complete_deposit:
                              !r.requires_complete_deposit,
                          })
                        }
                      >
                        {r.requires_complete_deposit ? (
                          <i className="fas fa-check" />
                        ) : (
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        )}
                      </button>
                    </Tooltip>
                  </Td>
                  <Td>{r.kyc_requirement}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Pagination
          total_pages={this.props.meta?.total_pages || 0}
          update={(page) => {
            this.setState({ page }, () => this.update());
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.payments.provider_search_results,
  platforms: state.payments.platforms,
});

const mapDispatchToProps = (dispatch) => ({
  editPaymentMethodPlatform: (id, platform) =>
    dispatch(editPaymentMethodPlatform(id, platform)),
  update: (filters) => dispatch(updatePaymentProviders(filters)),
  edit: (id, props) => dispatch(editProvider(id, props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderFinder);
