import { useCallback, useEffect, useMemo, useState } from "react";
import { MANIFEST_STATUS } from "./type";

export default function useUpdateMysteryBoxModal({
  isOpen = false,
  onClose,
  price,
  items,
  mystery_box,
  calculatePrice,
  ...props
}){
  const [updateMysteryBoxFormState, setUpdateMysteryBoxFormState] = useState({
    mystery_box_manifests: []
  });

  const manifestsAvailables = useMemo(
    () => updateMysteryBoxFormState.mystery_box_manifests
      .filter(m => m.status !== MANIFEST_STATUS.DELETE),
    [updateMysteryBoxFormState.mystery_box_manifests]
  );

  const total_odds = useMemo(() => 
    manifestsAvailables.reduce(
    (t, item) => t + parseFloat(item.chance || 0)
    , 0).toFixed(6),
    [manifestsAvailables]
  );

  const addItem = (id) => {
    const manifestIndex = updateMysteryBoxFormState.mystery_box_manifests.findIndex((m) => 
      m.reward_id === id
    );

    if (manifestIndex !== -1) {
      updateMysteryBoxFormState.mystery_box_manifests[manifestIndex] = {
        ...updateMysteryBoxFormState.mystery_box_manifests[manifestIndex],
        chance: 0,
        status: MANIFEST_STATUS.UPDATE
      }
    } else {
      updateMysteryBoxFormState.mystery_box_manifests.push({
        chance: 0,
        reward_id: id,
        status: MANIFEST_STATUS.NEW
      })
    }

    setUpdateMysteryBoxFormState({
      mystery_box_manifests: [...updateMysteryBoxFormState.mystery_box_manifests]
    });
    
  }

  const normalizeManifests = useCallback((manifest) => {
    const copy = JSON.parse(JSON.stringify(manifest));

    return copy.map(
      manifest => {
        manifest.chance = (parseFloat(manifest.chance) / 100).toFixed(6);
        return manifest;
      }
    )
  }, []);

  const editItem = (id, { chance }) => {
    const copy = JSON.parse(JSON.stringify(updateMysteryBoxFormState.mystery_box_manifests));
    const item = copy.find(i => i.reward_id === id);

    item.chance = chance || item.chance;

    setUpdateMysteryBoxFormState({
      mystery_box_manifests: [...copy]
    });
  }

  const removeItem = (id) => {
    const manifestIndexFounded = updateMysteryBoxFormState.mystery_box_manifests.findIndex((m) => m.reward_id === id);

    if(updateMysteryBoxFormState.mystery_box_manifests[manifestIndexFounded].status === 'NEW'){
      updateMysteryBoxFormState.mystery_box_manifests.splice(manifestIndexFounded, 1);
    } else {
      updateMysteryBoxFormState.mystery_box_manifests[manifestIndexFounded] = {
        ...updateMysteryBoxFormState.mystery_box_manifests[manifestIndexFounded],
        status: MANIFEST_STATUS.DELETE
      }
    }
    
    setUpdateMysteryBoxFormState({
      mystery_box_manifests: [...updateMysteryBoxFormState.mystery_box_manifests]
    });
  }

  const updateBox = (e) => {
      e.preventDefault();
      props.updateBoxRewards(
        mystery_box.id,
        {
          mystery_box_manifests: normalizeManifests(updateMysteryBoxFormState.mystery_box_manifests)
        },
        (id) => props.history.push(`/casino/mystery-boxes/${id}`)
      )
    }
  
  useEffect(() => {
    if(isOpen) {
      setUpdateMysteryBoxFormState({
        mystery_box_manifests: mystery_box.rewards.map(r => ({
          reward_id: r.id,
          id: r.manifest.id,
          status: MANIFEST_STATUS.UPDATE,
          chance: r.manifest.chance * 100,
          name: r.name,
          image: r.image_url,
          eur_value: r.eur_value,
        })),
      })
      props.updateItems()
    }
  }, [isOpen])

  useEffect(() => {
    if(isOpen && manifestsAvailables.length > 0) {
      calculatePrice({
        mystery_box_manifests: normalizeManifests(updateMysteryBoxFormState.mystery_box_manifests)
      })
    }
  }, [manifestsAvailables, isOpen])

  return {
    total_odds,
    manifestsAvailables,
    addItem,
    editItem,
    removeItem,
    updateBox,
  }
}