import React, { memo } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { calculatePrice, updateRewards, updateBoxRewards } from "../../reward-actions";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import _ from "lodash";
import useUpdateMysteryBoxModal from "./use-update-mystery-box-modal.hook";

const mapStateToProps = state => ({
  mystery_box: state.rewards.mystery_box,
  price: state.rewards.price,
  items: state.rewards.items.records,
});

const mapDispatchToProps = dispatch => ({
  calculatePrice: props => dispatch(calculatePrice(props)),
  updateItems: () => dispatch(updateRewards(1, 1)),
  updateBoxRewards: (id, props, redirect) => dispatch(updateBoxRewards(id, props, redirect))
});

const customStyles = {
  content: {
    top: "25%",
    left: "25%",
    right: "25%",
    marginRight: "-25%",
    transform: "translate(-20%, -20%)",
    backgroundColor: "#131521",
  },
};

function UpdateMysteryBoxModal(props){
  
  const {
    total_odds,
    manifestsAvailables,
    addItem,
    editItem,
    removeItem,
    updateBox
  } =useUpdateMysteryBoxModal(props);

  const ItemsSelect = memo(() => 
    props.items
      .filter(i => !manifestsAvailables.map(m => m.reward_id).includes(i.id))
      .map(
        item =>
          <div
            onClick={
              () => addItem(item.id)
            }
            className="wheel-item" style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              borderBottom: "1px solid",
            }}
            key={`items-select-${item.id}`}
          >
            <div>
              <img src={item.image_url} style={{ width: "64px" }}/>
            </div>
            <div>
              <div className="name">{item.name} - ID: {item.id}</div>
              <div className="name">{item.description}</div>
              <div className="type">{item.type}</div>
              <div className="value">€{item.eur_value}</div>
            </div>
            
          </div>
      )
  )

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} style={customStyles} contentLabel="update-mystery-box-modal">
      <h3>Add Rewards to Box</h3>
      <div className="items-select">
        <ItemsSelect/>
      </div>
      <h3>Set Odds</h3>
      <Table className="odds">
        <Thead>
          <Tr>
            <Th>Item Name</Th>
            <Th>Chance</Th>
            <Th>EV</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            props.items.length > 0 && manifestsAvailables.map(
              manifest => {
                const item = props.items.find(
                  i => i.id === manifest.reward_id
                ) || manifest;
                const ev = parseFloat(manifest.chance) / 100 * parseFloat(item.eur_value)
                return (
                  <Tr className='odd' key={`item-manifest-${item?.id}`}>
                    <Td>
                      <img style={{ width: '20px' }} src={item.image_url} />
                      {item.name} (€{item.eur_value})
                    </Td>
                    <Td>
                      <input
                        value={manifest.chance}
                        onChange={
                          e => editItem(manifest.reward_id, {
                            chance: e.target.value
                          })
                        }
                        style={{ maxWidth: '100%' }}
                      />
                    </Td>
                    <Td>
                      {ev ? ev.toFixed(6) : 0}
                    </Td>
                    <Td>
                      <a
                        onClick={() => removeItem(
                          manifest.reward_id
                        )}
                      >
                        <i className="fas fa-trash-alt" />
                      </a>
                    </Td>
                  </Tr>
                )
              }
            )
          }
          <Tr>
            <Td />
            <Td>Total Odds: {total_odds || 0}</Td>
            <Td>€ EV: {props.price}</Td>
          </Tr>
        </Tbody>
      </Table>
      <br />

      <button
        disabled={+total_odds !== 100 || manifestsAvailables < 2}
        onClick={updateBox}
      >Submit
      </button>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateMysteryBoxModal));