import React, { forwardRef } from "react";

const CustomInput = forwardRef(({ value, onClick }, ref) => {
  const format = value.split("-");
  return (
    <div className="input-calendar" onClick={onClick} ref={ref}>
      <p>
        <span>From</span> {format[0].replace(/\//g, "-")} <span>To</span>{" "}
        {format[1] ? format[1].replace(/\//g, "-") : "-"}
      </p>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99998 11.4L4.29998 7.69998C3.91338 7.31338 3.91338 6.68658 4.29998 6.29998C4.68658 5.91338 5.31338 5.91338 5.69998 6.29998L7.99998 8.59998L10.3 6.29998C10.6866 5.91338 11.3134 5.91338 11.7 6.29998C12.0866 6.68658 12.0866 7.31338 11.7 7.69998L7.99998 11.4Z"
          fill="#8C9099"
        />
      </svg>
    </div>
  );
});
export default CustomInput;
