

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateBox } from "../reward-actions";
import { Link } from 'react-router-dom';
import { Table, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import './mystery-box.css';
import UpdateMysteryBoxModal from './update-mystery-box-modal';
import * as types from '../reward-types';
import { permissionsConstants } from 'app/permissions-constants';
import { PermissionsFragment } from 'app/permissions-fragment';

class MysteryBox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      page: 1
    }
  }

  componentDidMount() {
    this.props.update(this.props.match.params.id);
  }

  updateModal(state){
    return () => {
      this.props.updateModalState(state)
    }
  }

  render() {

    const MysteryBoxDataComponent = React.memo(() => 
      <div className='mystery-box-data'>
        {
          Object.keys(this.props.mystery_box).map(key => {
            const value = this.props.mystery_box[key];
            if (typeof value === 'object') return null;
            return (
              <div key={`key-${key}`} >
                <span>{key}: </span>
                <span>{value}</span>
              </div>
            );
          })
        }
      </div>
    )

    return (
      <div className='mystery-box'>
        <MysteryBoxDataComponent/>
        <hr />
        <h2>Win Metrics</h2>
        <pre>
          {JSON.stringify(this.props.mystery_box.win_metrics)}
        </pre>
        <hr />
        <div className='mystery-box-table-header'>
          <h3>Items</h3>
          <PermissionsFragment 
            feature={permissionsConstants.MYSTERY_BOXES_UPDATE_BOX}
          >
            <button onClick={this.updateModal(true)}>Edit</button>
            <UpdateMysteryBoxModal
              isOpen={this.props.is_modal_open}
              onClose={this.updateModal(false)}
            />
          </PermissionsFragment>
        </div>        
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Name</Th>
              <Th>Type</Th>
              <Th>Image</Th>
              <Th>€ Value</Th>
              <Th>Chance</Th>
              <Th>Min Roll (1-1m)</Th>
              <Th>Max Roll (1-1m)</Th>
            </Tr>
          </Thead>

          <tbody className='mystery-box-rewards'>
            {
              this.props.mystery_box.rewards.map((r, i) => {
                return (
                  <Tr key={i}>
                    <Td>
                      <Link to={`/rewards/${r.id}`}>
                        {r.id}
                      </Link>
                    </Td>
                    <Td>
                      <Link to={`/rewards/${r.id}`}>
                        {r.name}
                      </Link>
                    </Td>
                    <Td>
                      {r.type}
                    </Td>
                    <Td>
                      <img src={r.image_url} alt="" />
                    </Td>
                    <Td>
                      € {r.eur_value}
                    </Td>
                    <Td>
                      {(+r.manifest.chance * 100).toFixed(6)}
                    </Td>
                    <Td>
                      {r.manifest.minimum_roll}
                    </Td>
                    <Td>
                      {r.manifest.maximum_roll}
                    </Td>
                  </Tr>
                )
              })
            }
          </tbody>
        </Table>
        
      </div>
    )
  }
}

const mapStateToProps = state => ({
  mystery_box: state.rewards.mystery_box,
  is_modal_open: state.rewards.mystery_box_modal_state,
});

const mapDispatchToProps = dispatch => ({
  update: id => dispatch(updateBox(id)),
  updateModalState: (state) => dispatch({ type: types.CASE_MODAL_STATUS_UPDATE, payload: state })
});

export default connect(mapStateToProps, mapDispatchToProps)(MysteryBox);